@import '../../../default';

.page-adm-store{

    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;
    }

    .p-fileupload-buttonbar{
        
        .p-button{
            &:nth-child(2){
                display: none;
            }
        }
    }

    .area-preview{
        
        width: 100%;
        height: 180px;
        border: 1px solid #ddd;
        margin: 10px auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;

        > div{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            > img {
                width: auto;
                height: 100%;
                display: block;
            }

            .remove{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                max-height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.3);
                color: #FFF;
                box-sizing: border-box;
                padding: 10px;
                font-size: 2em;
                cursor: pointer;
                transition: all ease 0.5s;

                &:hover{
                    background-color: rgba(0, 0, 0, 0.5);
                    transition: all ease 0.5s;
                    height: 100%;
                    max-height: 100%;
                }
            }
        }

        &:empty{
            width: 100%;
            height: 180px;
            display: block;
            border: 1px solid #ddd;
            margin: 10px auto;
            position: relative;
            box-sizing: border-box;
            padding: 10px;

            &::before{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 50px;
                height: 50px;
                border: 2px solid #CCC;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                background-image: linear-gradient(45deg, transparent 49%, #CCC 49%, #CCC 51%, transparent 51%, transparent),
                                linear-gradient(-45deg, transparent 49%, #CCC 49%, #CCC 51%, transparent 51%, transparent);
            }
        }
    }

}