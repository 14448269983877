@import '../../../default';

.card-product{
    display: grid;
    grid-template-columns: 110px 1fr 40px;
    row-gap: 5px;
    column-gap: 5px;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 1px;


    .area-image{

        grid-row: 1 / span 3;
        overflow: hidden;
        
        .image-loader{
            max-width: 100%;
            width: 100px;
            height: 100px;
            background-color: #f7f6f6;
            overflow: hidden;
            border-radius: 1px;

            img{
                width: auto;
                max-height: 80px !important;
                height: 100%;
                display: block;
            }

        }

        > div{
            max-width: 120px !important;
        }

    }

    .product-name{
        font-weight: 700;
        font-size: 1em;
        text-overflow: ellipsis;
        width: 100%;
        max-width: calc(100% - 20px);
        overflow: hidden;
        white-space: nowrap;
        grid-column: 2 / span 3;

        @media screen and (max-width: 500px) {
            font-size: 0.8em;
        }
    }

    .product-description{
        width: calc(100% - 20px);
        height: 20px;
        max-height: 20px;
        font-weight: 400;
        text-overflow: ellipsis;
        margin: 2.5px 0;
        overflow: hidden;
        white-space: nowrap;
        grid-column: 2;
        font-size: 0.8em;

        @media screen and (max-width: 500px) {
            font-size: 0.8em;
        }
    }

    .product-price{
        grid-column: 2;
        font-size: 0.9em;

        @media screen and (max-width: 500px) {
            font-size: 0.8em;
        }
    }

}


.card-promotional{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    column-gap: 5px;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 1px;
    max-width: 280px;
    margin: 5px auto;


    .area-image{

        overflow: hidden;
        
        .image-loader{
            max-width: 100%;
            height: auto;
            background-color: #f7f6f6;
            overflow: hidden;
            border-radius: 1px;
            margin: 0 auto;

            img{
                width: auto;
                max-height: 80px !important;
                height: 100%;
                display: block;
            }

        }
    }

    .product-name{
        font-weight: 700;
        font-size: 1em;
        text-overflow: ellipsis;
        width: 100%;
        max-width: calc(100% - 20px);
        overflow: hidden;
        white-space: nowrap;

        @media screen and (max-width: 500px) {
            font-size: 0.8em;
        }
    }

    .product-description{
        width: calc(100% - 20px);
        height: 20px;
        max-height: 20px;
        font-weight: 400;
        text-overflow: ellipsis;
        margin: 2.5px 0;
        overflow: hidden;
        white-space: nowrap;
        font-size: 0.8em;

        @media screen and (max-width: 500px) {
            font-size: 0.8em;
        }
    }

    .product-price{
        font-size: 0.9em;

        @media screen and (max-width: 500px) {
            font-size: 0.8em;
        }
    }

    .product-action{
        box-sizing: border-box;
        padding: 5px 0;
    }

}