@import '../../default';

.page-home{

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: linear-gradient(0, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../../image/bg_cover.jpg);
    background-size: 100% 100%, cover auto;

    .main-frame{
        width: 90%;
        max-width: 600px;
        box-sizing: border-box;
        padding: 20px 30px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 5px;
    }

}