@import '../../../default';

.page-adm-home{

    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;
    } 
    
    .card-box{
        box-sizing: border-box;
        padding: 5px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(48%, auto));
        row-gap: 10px;
        column-gap: 10px;

        .card{
            background-color: var(--backgroundCard);
            color: var(--colorCard);
            box-sizing: border-box;
            padding: 10px;
            border-radius: 5px;
            display: grid;
            grid-template-columns: 1fr 32px;
            row-gap: 5px;
            column-gap: 5px;

            .title{
                grid-column: 1 / span 2;
            }

            .icon, .value{
                text-align: right;
            }
        }

    }

}