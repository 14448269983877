@import '../../../default';

.page-user-address{

    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;

        .card{
            border-radius: 5px;
            border: 1px solid #ccc;
            box-sizing: border-box;
            padding: 10px 20px;
            margin: 20px auto;

            &.delete{
                pointer-events: none;
                opacity: 0.3;
            }

        }

        label{
            font-size: 0.8em;
        }

        .area-button{
            text-align: right;

            .p-button{
                width: 100%;
            }

            @media screen and (max-width: 500px) {
                text-align: center;
            }
        }
    }
}