@import '../../default';

.page-cart-list{

    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;

        .p-placeholder{
            font-size: 0.8em;
        }

        .payment-list-enabled{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(40px, 120px));
            column-gap: 10px;
            box-sizing: border-box;
            padding: 5px;
            align-items: center;
            justify-content: center;
            font-size: 0.8em;
            
            > span {
                box-sizing: border-box;
                padding: 5px;
                background-color: #eee;
                text-align: center;
            }
        }
    }
}