@import '../../../default';

.page-user-request{

    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;
    }

    .avatar{
        width: 80px;
        box-sizing: border-box;
        padding: 10px;

        svg{
            width: 3em;
            height: 3em;
        }
    }

    .status{
        display: inline-flex;
        align-items: center;

        &::before{
            width: 10px;
            height: 10px;
            box-sizing: border-box;
            content: '';
            display: inline-block;
            border-radius: 50%;
            margin: 2.5px 5px;
        }

        &.on{
            &::before{
                background-color: var(--green-400);
                animation: stauson 1s linear infinite;
            }
        }

        &.close{
         &::before{
             background-color: var(--green-400);
         }
         
         }

         &.cancel{
             &::before{
                 background-color: #F00;
                 border: 0;
             }
             
             }

        @keyframes stauson {
             0%,100%{
                 box-shadow: 0 0 0 var(--green-700);
             }
             50%{
                 box-shadow: 0 0 5px var(--green-700),
                             0 0 7px var(--green-700);
             }
        }

        label{
            font-size: 0.8em;
        }

        .area-button{
            text-align: right;

            .p-button{
                width: 100%;
            }

            @media screen and (max-width: 500px) {
                text-align: center;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
        .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
            display: none !important;
        }

        .pageadm-request .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td{
            strong{
                display: inline-block;
            }
        }
    
        .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
            text-align: left;
            display: block;
            width: 100%;
            float: left;
            clear: left;
            border: 0 none;
        }
    
        .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4em 1em -.4em -.4rem;
            font-weight: bold;
        }
    
        .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
            border-bottom: 1px solid var(--surface-d);
        }

        [role="row"] [role="cell"]{
            width: 100% !important;
            min-width: 100%;
            > button{
                width: 100%;
            }
        }
    }
}