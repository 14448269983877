@import '../../../default';

.page-user-login{
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url(../../../../image/bg_cover.jpg);
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-y: auto;

    &::before{
        content: '';
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;

    }

    .box{
        width: 96%;
        max-width: 600px;
        background-color: rgba(255, 255, 255, 0.9);
        position: relative;
        z-index: 2;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 5px;

        @media screen and (max-width: 500px) {
            padding: 20px;
            width: 98%;
        }

        .avatar{
            overflow: hidden;
            display: flex;
            width: 120px;
            min-width: 120px;
            height: 120px;
            min-height: 120px;
            border-radius: 50%;
            background-color: #FFF;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            @media screen and (max-width: 500px) {
                width: 80px;
                min-width: 80px;
                height: 80px;
                min-height: 80px;
            }

        }

        form{
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
        }

        .keyboard{
            display: grid;   
            grid-template-columns: repeat(6, 1fr);
            column-gap: 10px;
            row-gap: 10px;
            box-sizing: border-box;
            padding: 20px 0 0;

            @media screen and (max-width: 500px) {
                padding: 10px 0 0px;
            }

            .p-button{
                font-size: 0.8em;
            }

            @media screen  and (max-width: 500px){
                grid-template-columns: repeat(3, 1fr);   
            }
        }

        .recovery-access{
            box-sizing: border-box;
            padding: 10px 0 20px;
            cursor: pointer;
            text-decoration: underline;
            font-size: 0.8em;

            @media screen and (max-width: 500px) {
                padding: 8px 0;
            }
        }
    }
}