@import '../../../default';

.page-user-managerrequest{
    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;
    }

    .p-datatable .p-datatable-tbody > tr, .p-dropdown-label {
        font-size: 0.8em;
    }
}