@import '../../../default';


.page-delivery-inroute{
    
    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;
    }

    .area-map{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;

        .map{
            width: 100%;
            height: 300px;
            min-height: 300px;
            background-color: #eee;
            box-sizing: border-box;
            padding: 10px;
        }
    }

    .resume{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;
    }

}