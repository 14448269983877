@import '../../default';

.page-base{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 75px 1fr;

    section{
        box-sizing: border-box;
        width: 100%;
        overflow: auto;
        scroll-behavior: smooth;
        -ms-scroll-snap-type: mandatory;
        scroll-snap-type: mandatory;
    }
}