@import '../../../../default';

.page-user-payment-create{

    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;

        label{
            font-size: 0.8em;
        }

        .area-button{
            text-align: right;

            .p-button{
                width: 100%;
            }

            @media screen and (max-width: 500px) {
                text-align: center;
            }
        }
    }
}