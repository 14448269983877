@import 'default';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;900&display=swap');

@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

@import 'primeflex/primeflex.css';

@import 'primeflex/src/_variables.scss';
@import 'primeflex/src/_grid.scss';
@import 'primeflex/src/_formlayout.scss';
@import 'primeflex/src/_display.scss';
@import 'primeflex/src/_text.scss';
@import 'primeflex/src/flexbox/_flexbox.scss';
@import 'primeflex/src/_spacing.scss';
@import 'primeflex/src/_elevation.scss';

html{

    margin: 0;
    padding: 0;
    
    box-sizing: border-box;

    body{

        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        

        #root{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }

    }

}