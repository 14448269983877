@import '../../../default';

.page-adm-productlist,
.page-adm-productcreate{

    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;
    }

    .p-fileupload-buttonbar{
        
        .p-button{
            &:nth-child(2){
                display: none;
            }
        }
    }

    .preview-image{

        .remove{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.3);
            color: #FFF;
            box-sizing: border-box;
            padding: 10px;
            font-size: 2em;
            cursor: pointer;
            transition: all ease 0.5s;

            &:hover{
                background-color: rgba(0, 0, 0, 0.5);
                transition: all ease 0.5s;
                height: 100%;
                max-height: 100%;
            }
        }
    }

}