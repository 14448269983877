@import '../../../../default';

.page-user-request-view{

    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;

        .status{
            box-sizing: border-box;
            padding: 5px 15px;
            background-color: var(--surface-50);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .list-items{
            font-size: 0.8em;
            box-sizing: border-box;
            padding: 5px 10px;

            .num-item{
                display: flex;
                min-width: 20px;
                width: 20px;
                min-height: 20px;
                height: 20px;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                background-color: var(--green-100);
                font-size: 0.7em;
            }

            > div:nth-child(even) {
                background-color: var(--surface-50);
            }
        }

        .avatar{
            overflow: hidden;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 100px;
            text-align: center;
            margin: 0 auto;
        }

        .status-steps{
            position: relative;
            display: flex;
            justify-content: space-around;
            box-sizing: border-box;
            margin: 20px auto;

            &::before{
                position: absolute;
                width: 100%;
                height: 15px;
                border-bottom: 1px #ccc dashed;
                content: '';
            }

            > div {
                width: 32px;
                min-width: 32px;
                height: 32px;
                min-height: 32px;
                border-radius: 50%;
                display: flex;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 0.8em;
                filter: grayscale(1);
                transition: all ease 600ms;
                transform: scale(0.8);

                &.active{
                    filter: grayscale(0);
                    transition: all ease 600ms;
                    transform: scale(1);
                }

                .text{
                    margin: 5px;
                }

                &.request{
                    &.status-0{
                        position: relative;
                        &::before{
                         content: '';   
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         width: 15px;
                         height: 15px;
                         border-radius: 50%;
                         background-color: #0F0;
                         transform: translate(-50%, -50%);
                         animation: stauson 1s linear infinite;
                        }
                    }

                    &.status-1{
                        position: relative;
                        &::before{
                         content: '';   
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         width: 10px;
                         height: 10px;
                         border-radius: 50%;
                         background-color: #0F0;
                         transform: translate(-50%, -50%);
                        }
                    }
                }

                &.store{
                    &.status-0{
                        position: relative;
                        &::before{
                         content: '';   
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         width: 15px;
                         height: 15px;
                         border-radius: 50%;
                         background-color: #0F0;
                         transform: translate(-50%, -50%);
                         animation: stauson 1s linear infinite;
                        }
                    }

                    &.status-1,  &.status-2{
                        position: relative;
                        &::before{
                         content: '';   
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         width: 10px;
                         height: 10px;
                         border-radius: 50%;
                         background-color: #0F0;
                         transform: translate(-50%, -50%);
                        }
                    }
                }

                &.production{
                    &.status-1{
                        position: relative;
                        &::before{
                         content: '';   
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         width: 15px;
                         height: 15px;
                         border-radius: 50%;
                         background-color: #0F0;
                         transform: translate(-50%, -50%);
                         animation: stauson 1s linear infinite;
                        }
                    }

                    &.status-2{
                        position: relative;
                        &::before{
                         content: '';   
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         width: 10px;
                         height: 10px;
                         border-radius: 50%;
                         background-color: #0F0;
                         transform: translate(-50%, -50%);
                        }
                    }
                }

                &.delivery{
                    &.status-0, &.status-1{
                        position: relative;
                        &::before{
                         content: '';   
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         width: 15px;
                         height: 15px;
                         border-radius: 50%;
                         background-color: #0F0;
                         transform: translate(-50%, -50%);
                         animation: stauson 1s linear infinite;
                        }
                    }

                    &.status-2{
                        position: relative;
                        &::before{
                         content: '';   
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         width: 10px;
                         height: 10px;
                         border-radius: 50%;
                         background-color: #0F0;
                         transform: translate(-50%, -50%);
                        }
                    }
                }

                &.client{
                   
                    &.status-2{
                        position: relative;
                        &::before{
                         content: '';   
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         width: 10px;
                         height: 10px;
                         border-radius: 50%;
                         background-color: #0F0;
                         transform: translate(-50%, -50%);
                        }
                    }
                }
            }

            @keyframes stauson {
                0%,100%{
                    box-shadow: 0 0 0 var(--green-700);
                }
                50%{
                    box-shadow: 0 0 5px var(--green-700),
                                0 0 7px var(--green-700);
                }
           }

            
        }


        label{
            font-size: 0.8em;
        }

        .area-button{
            text-align: right;

            .p-button{
                width: 100%;
            }

            @media screen and (max-width: 500px) {
                text-align: center;
            }
        }

        .itens-request{
            li{
                list-style: none;

                ul{
                    margin: 0;
                    padding: 0 15px;
                    li{
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}